import config from 'config'
import toaster from 'toaster'

const SET_MESSAGES = 'SET_MESSAGES'
const ADD_MESSAGE = 'ADD_MESSAGE'
const CLONE_MESSAGE = 'CLONE_MESSAGE'
const REPLACE_MESSAGE = 'REPLACE_MESSAGE'
const REMOVE_MESSAGE = 'REMOVE_MESSAGE'

export const setMessages = messages => ({
  type: SET_MESSAGES,
  messages
})

export const addMessage = message => ({
  type: ADD_MESSAGE,
  message
})

export const cloneMessage = message => ({
  type: CLONE_MESSAGE,
  message
})

export const replaceMessage = (messageId, message) => ({
  type: REPLACE_MESSAGE,
  messageId,
  message
})

export const removeMessage = messageId => ({
  type: REMOVE_MESSAGE,
  messageId
})

export const messages = (state = [], action) => {
  switch (action.type) {
    case SET_MESSAGES:
      return action.messages

    case ADD_MESSAGE:
      return state.concat(action.message)

    case CLONE_MESSAGE:
      return state.concat({
        ...action.message.message,
        redirect: '/dashboard'
      })

    case REPLACE_MESSAGE:
      return state.map((message) => {
        if (message.messageId === action.messageId) {
          return {
            ...action.message,
            redirect: '/dashboard'
          }
        }
        else {
          return message
        }
      })

    case REMOVE_MESSAGE:
      return state.filter(message => message.messageId !== action.messageId)

    default:
      return state
  }
}

export const fetchMessage = (appId, messageId) => async (dispatch, state) => {
  const url = config.api.baseUrl + '/' + appId + '/messages/' + messageId
  const token = state().user.token

  const response = await fetch(url, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    const json = await response.json()
    dispatch(setMessages([json]))
  }
}

export const fetchMessages = appId => async (dispatch, state) => {
  const url = config.api.baseUrl + '/' + appId + '/messages'
  const token = state().user.token

  const response = await fetch(url, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    const json = await response.json()
    dispatch(setMessages(json))
  }
}

export const postMessage = fields => async (dispatch, state) => {
  let url = config.api.baseUrl + '/' + fields.app + '/messages'
  let verb = 'POST'
  const token = state().user.token

  if (fields.messageId) {
    url += '/' + fields.messageId
    verb = 'PUT'
  }

  let params = {
    channels: Object.keys(fields.channels),
    title: fields.title,
    body: fields.body,
    url: '',
    screen: '',
    silent: fields.silent ? true : false,
    scheduled: fields.scheduled,
    expires: fields.expires,
    send: fields.send
  }

  // Mutually-exclusive landing action: URL or screen
  if (fields.link === 'url' && fields.url.trim()) {
    params.url = fields.url.trim()
  }
  else if (fields.link === 'screen' && fields.screen) {
    params.screen = fields.screen
  }

  const response = await fetch(url, {
    method: verb,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(params)
  })

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    const json = await response.json()
    let message

    if (fields.scheduled && fields.send) {
      message = 'Your message was successfully scheduled!'
    }
    else if (fields.send) {
      message = 'Your message was successfully sent!'
    }
    else if (fields.clone) {
      message = 'A new copy of the message has been created.'
    }
    else {
      message = 'Message saved for later.'
    }

    toaster.show({ message })

    if (fields.messageId) {
      dispatch(replaceMessage(json.uuid, json.message))
    }
    else if (fields.clone) {
      dispatch(cloneMessage(json))
    }
    else {
      dispatch(addMessage(json))
    }
  }
}

export const deleteMessage = (appId, messageId) => async (dispatch, state) => {
  const url = config.api.baseUrl + '/' + appId + '/messages/' + messageId
  const token = state().user.token

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    dispatch(removeMessage(messageId))
  }
}
