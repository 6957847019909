import React from 'react'
import { H1, H2 } from '@blueprintjs/core'

import Colors from 'styles/colors'

import tnCreate from 'images/tnCreate.png'
import tnSend from 'images/tnSend.png'
import tnClone from 'images/tnClone.png'
import tnView from 'images/tnView.png'
import documentIcon from 'images/document.svg'

class Tutorials extends React.Component {
  static videos = [{
    link: 'https://youtu.be/xzw6l3p5uKk',
    thumbnail: tnCreate,
    text: 'Create a New Message'
  }, {
    link: 'https://youtu.be/bSGC-7AwLDw',
    thumbnail: tnSend,
    text: 'Send a Pending Message'
  }, {
    link: 'https://youtu.be/Z_vIxEprjaA',
    thumbnail: tnClone,
    text: 'Clone a Message'
  }, {
    link: 'https://youtu.be/yG9iDx967K0',
    thumbnail: tnView,
    text: 'View Scheduled and Message Activities'
  }]

  render() {
    return (
      <div>
        <div style={{ textAlign: 'center' }}>
          <H1 style={{ marginBottom: 0 }}>
            Tutorials
          </H1>
          <p style={{ fontSize: '24px' }}>
            Learn how to message directly to channels and increase engagement
          </p>
        </div>

        <H2>
          Video Tutorials
        </H2>

        <div>
          {this.renderVideos()}
        </div>

        <H2>
          Learning Resources
        </H2>

        <div style={{ lineHeight: '25px' }}>
          <a
            href="/ZAP.Msg.Cheat.Sheet.7.17.20.pdf"
            style={{ fontSize: '14px', fontWeight: 600 }}
          >
            <img
              src={documentIcon}
              alt=""
              style={{ verticalAlign: 'top', marginRight: '10px' }}
            />
            QUICK GUIDE
          </a>
        </div>
      </div>
    )
  }

  renderVideos() {
    return Tutorials.videos.map(video => (
      <a
        key={video.link}
        href={video.link}
        style={{
          display: 'inline-block',
          width: '320px',
          marginBottom: '20px',
          paddingRight: '20px',
          verticalAlign: 'top',
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: 500,
          textDecoration: 'none',
          color: Colors.darkGray
        }}
      >
        <img
          src={video.thumbnail}
          alt=""
          style={{ width: '300px', marginBottom: '5px' }}
        />
        {video.text}
      </a>
    ))
  }
}

export default Tutorials
