import React from 'react'
import { connect } from 'react-redux'
import {
  H1,
  RadioGroup,
  Radio,
  FormGroup,
  InputGroup
} from '@blueprintjs/core'
import queryString from 'query-string'

import { fetchApps } from 'store/apps'
import { verify } from 'store/subscription'
import Status from 'components/Status'
import StyledButton from 'components/StyledButton'

class Verify extends React.Component {
  constructor(props) {
    super(props)

    const params = queryString.parse(props.location.search)
    let method = ''
    let sms = ''

    if (params.email) {
      method = 'email'
    }
    else if (params.sms) {
      method = 'sms'
      sms = params.sms.replace(/^\+1/, '')
    }

    this.state = {
      method,
      email: params.email || '',
      sms,
      code: params.code || ''
    }
  }

  componentDidMount() {
    this.props.fetchApps()
  }

  render() {
    if (this.props.apps.length < 1) {
      return null
    }

    const matchingApp = this.props.apps.find(app => (
      app.id === this.props.match.params.appId
    ))

    if (!this.props.match.params.appId || !matchingApp) {
      return (
        <Status>
          App not found.
        </Status>
      )
    }

    else if (this.props.subscription.email) {
      return (
        <Status>
          <p>
            {this.props.subscription.email} is now opted into notifications!
          </p>
        </Status>
      )
    }

    else if (this.props.subscription.sms) {
      return (
        <Status>
          <p>
            {this.props.subscription.sms} is now opted into notifications!
          </p>
        </Status>
      )
    }

    else {
      return (
        <Status>
          <H1>
            {matchingApp.displayName}
          </H1>

          <RadioGroup
            label="Communication Method"
            name="method"
            selectedValue={this.state.method}
            onChange={this.changeInput}
            inline
          >
            <Radio label="Email" value="email" />
            <Radio label="Text" value="sms" />
          </RadioGroup>

          {this.renderEmailInput()}
          {this.renderSMSInputs()}
          {this.renderCodeInput()}
          {this.renderSubmitButton()}
        </Status>
      )
    }
  }

  renderEmailInput() {
    if (this.state.method !== 'email') {
      return null
    }

    return (
      <FormGroup
        labelFor="email"
        label="Email Address"
        inline
        style={{ justifyContent: 'center' }}
      >
        <InputGroup
          type="email"
          id="email"
          name="email"
          value={this.state.email}
          onChange={this.changeInput}
        />
      </FormGroup>
    )
  }

  renderSMSInputs() {
    if (this.state.method !== 'sms') {
      return null
    }

    return (
      <div>
        <p>
          Please enter a 10-digit US phone number.
          Non-digit characters are ignored.
        </p>
        <FormGroup
          labelFor="sms"
          label="Phone Number"
          inline
          style={{ justifyContent: 'center' }}
        >
          <InputGroup
            type="tel"
            id="sms"
            name="sms"
            value={this.state.sms}
            onChange={this.changeInput}
          />
        </FormGroup>
      </div>
    )
  }

  renderCodeInput() {
    if (!this.state.method) {
      return null
    }

    return (
      <FormGroup
        labelFor="code"
        label="Code"
        inline
        style={{ justifyContent: 'center' }}
      >
        <InputGroup
          type="number"
          id="code"
          name="code"
          value={this.state.code}
          onChange={this.changeInput}
        />
      </FormGroup>
    )
  }

  renderSubmitButton() {
    if (!this.state.method) {
      return null
    }

    return (
      <StyledButton
        colorClass="yellow"
        onClick={this.submit}
        style={{ width: '180px' }}
        disabled={!this.isFormValid()}
      >
        Verify
      </StyledButton>
    )
  }

  changeInput = (event) => {
    let newState = {}
    newState[event.currentTarget.name] = event.currentTarget.value

    this.setState(newState)
  }

  submit = () => {
    this.props.verify(this.props.match.params.appId, this.getPrettyState())
  }

  isFormValid() {
    const prettyState = this.getPrettyState()

    if (!prettyState.code) {
      return false
    }

    else if (prettyState.method === 'email') {
      // Extremely basic email test, just to filter out basic mistakes.
      // No point in full validation here since a confirmation email must
      // be answered anyway.
      return /^[^@]+@[^.]+\..+/.test(prettyState.email)
    }

    else if (prettyState.method === 'sms') {
      // Only support US numbers for now
      return prettyState.sms.length === 10
    }

    return false
  }

  getPrettyState() {
    return {
      method: this.state.method,
      email: this.state.email.trim(),
      sms: this.state.sms.trim().replace(/[^0-9]/g, ''),
      code: this.state.code.trim()
    }
  }
}

const mapStateToProps = state => ({
  apps: state.apps,
  subscription: state.subscription
})

const mapDispatchToProps = dispatch => ({
  fetchApps: () => dispatch(fetchApps()),
  verify: (appId, formData) => dispatch(verify(appId, formData))
})

export default connect(mapStateToProps, mapDispatchToProps)(Verify)
