import React from 'react'
import { connect } from 'react-redux'
import {
  H1,
  RadioGroup,
  Radio,
  FormGroup,
  InputGroup
} from '@blueprintjs/core'

import { fetchApps } from 'store/apps'
import { subscribe } from 'store/subscription'
import Status from 'components/Status'
import StyledButton from 'components/StyledButton'

class Subscribe extends React.Component {
  constructor() {
    super()

    this.state = {
      method: '',
      email: '',
      sms: ''
    }
  }

  componentDidMount() {
    this.props.fetchApps()
  }

  render() {
    if (this.props.apps.length < 1) {
      return null
    }

    const matchingApp = this.props.apps.find(app => (
      app.id === this.props.match.params.appId
    ))

    if (!this.props.match.params.appId || !matchingApp) {
      return (
        <Status>
          App not found.
        </Status>
      )
    }

    else if (this.props.subscription.email) {
      return (
        <Status>
          <p>
            An email has been sent to {this.props.subscription.email}.
            Please follow the instructions in it to confirm your subscription.
          </p>
        </Status>
      )
    }

    else if (this.props.subscription.sms) {
      return (
        <Status>
          <p>
            A text message has been sent to {this.props.subscription.sms}.
            Please follow the instructions in it to confirm your subscription.
          </p>
        </Status>
      )
    }

    else {
      return (
        <Status>
          <H1>
            {matchingApp.displayName}
          </H1>

          <RadioGroup
            label="Communication Method"
            name="method"
            selectedValue={this.state.method}
            onChange={this.changeInput}
            inline
          >
            <Radio label="Email" value="email" />
            <Radio label="Text" value="sms" />
          </RadioGroup>

          {this.renderEmailInput()}
          {this.renderSMSInputs()}
          {this.renderSubmitButton()}
        </Status>
      )
    }
  }

  renderEmailInput() {
    if (this.state.method !== 'email') {
      return null
    }

    return (
      <FormGroup
        labelFor="email"
        label="Email Address"
        inline
        style={{ justifyContent: 'center' }}
      >
        <InputGroup
          type="email"
          id="email"
          name="email"
          onChange={this.changeInput}
        />
      </FormGroup>
    )
  }

  renderSMSInputs() {
    if (this.state.method !== 'sms') {
      return null
    }

    return (
      <div>
        <p>
          Please enter a 10-digit US phone number.
          Non-digit characters are ignored.
        </p>
        <FormGroup
          labelFor="sms"
          label="Phone Number"
          inline
          style={{ justifyContent: 'center' }}
        >
          <InputGroup
            type="tel"
            id="sms"
            name="sms"
            onChange={this.changeInput}
          />
        </FormGroup>
      </div>
    )
  }

  renderSubmitButton() {
    if (!this.state.method) {
      return null
    }

    return (
      <StyledButton
        colorClass="yellow"
        onClick={this.submit}
        style={{ width: '180px' }}
        disabled={!this.isFormValid()}
      >
        Subscribe
      </StyledButton>
    )
  }

  changeInput = (event) => {
    const name = event.currentTarget.name
    let val = event.currentTarget.value
    let newState = {}

    // Strip non-numeric characters from phone
    if (name === 'sms') {
      val = val.replace(/[^0-9]/g, '')
    }

    newState[name] = val
    this.setState(newState)
  }

  submit = () => {
    this.props.subscribe(this.props.match.params.appId, this.state)
  }

  isFormValid() {
    if (this.state.method === 'email') {
      // Extremely basic email test, just to filter out basic mistakes.
      // No point in full validation here since a confirmation email must
      // be answered anyway.
      return /^[^@]+@[^.]+\..+/.test(this.state.email)
    }

    else if (this.state.method === 'sms') {
      // Only support US numbers for now
      return this.state.sms.length === 10
    }

    return false
  }
}

const mapStateToProps = state => ({
  apps: state.apps,
  subscription: state.subscription
})

const mapDispatchToProps = dispatch => ({
  fetchApps: () => dispatch(fetchApps()),
  subscribe: (appId, formData) => dispatch(subscribe(appId, formData))
})

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe)
