import React from 'react'
import PropTypes from 'prop-types'

import deleteIcon from 'images/delete.svg'

class DeleteButton extends React.Component {
  render() {
    return (
      <img
        src={deleteIcon}
        alt="Delete"
        style={{ cursor: 'pointer', verticalAlign: 'middle' }}
        onClick={this.delete}
      />
    )
  }

  delete = () => {
    const confirmed = window.confirm('Are you sure you want to delete this message?')

    if (confirmed) {
      this.props.onDelete(this.props.id)
    }
  }
}

DeleteButton.propTypes = {
  id: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default DeleteButton
