import React from 'react'
import { connect } from 'react-redux'
import { H2, Card, FormGroup } from '@blueprintjs/core'
import moment from 'moment'

import { fetchChannelRequest } from 'store/channelRequests'
import titleCase from 'helpers/titleCase'

import 'styles/ChannelRequest.css'

class ChannelRequest extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      channelRequestId: parseInt(props.match.params.id, 10)
    }
  }

  componentDidMount() {
    this.props.fetchChannelRequest('ucla', this.state.channelRequestId)
  }

  render() {
    let displayNameLabel = 'Channel Name'
    let emailLabel = "Sponsor's Email Address"

    const channelRequest = this.props.channelRequests.find(request => (
      request.id === this.state.channelRequestId
    ))

    if (!channelRequest || !channelRequest.requester) {
      return null
    }

    let department = (
      <FormGroup
        labelFor="department"
        label="Official Group or Department Name"
      >
        {channelRequest.department}
      </FormGroup>
    )

    let description = (
      <FormGroup labelFor="description" label="Description">
        {channelRequest.description}
      </FormGroup>
    )

    if (channelRequest.sponsored) {
      displayNameLabel = 'Suggested Channel Name'
      emailLabel = 'Suggested Campus Sponsor'
      department = null
      description = null
    }

    return (
      <div className="ChannelRequest">
        <H2>
          Requested Channel
        </H2>

        <Card>
          <div className="status">
            Status: {titleCase(channelRequest.status)}
          </div>

          <FormGroup labelFor="requester" label="Channel Manager">
            {titleCase(channelRequest.requester)}
          </FormGroup>

          <FormGroup labelFor="displayName" label={displayNameLabel}>
            {channelRequest.displayName}
          </FormGroup>

          {department}

          <FormGroup labelFor="email" label={emailLabel}>
            {channelRequest.email}
          </FormGroup>

          <FormGroup labelFor="need" label="Need">
            {channelRequest.need}
          </FormGroup>

          {description}

          <FormGroup labelFor="tags" label="Tags/Categories">
            {this.renderTags(channelRequest)}
          </FormGroup>

          <FormGroup labelFor="status" label="Status">
            {titleCase(channelRequest.status)}
          </FormGroup>

          {this.renderReviewedAt(channelRequest)}
          {this.renderFeedback(channelRequest)}
        </Card>
      </div>
    )
  }

  renderTags(channelRequest) {
    return channelRequest.tags.map(tag => (
      <div key={tag} className="react-tag">
        {tag}
      </div>
    ))
  }

  renderReviewedAt(channelRequest) {
    const reviewedAt = moment(channelRequest.reviewedAt)

    if (!reviewedAt.isValid()) {
      return null
    }

    return (
      <FormGroup labelFor="reviewedAt" label="Reviewed Date">
        {reviewedAt.format('L')}
      </FormGroup>
    )
  }

  renderFeedback(channelRequest) {
    if (!channelRequest.feedback) {
      return null
    }

    return (
      <FormGroup labelFor="feedback" label="Feedback">
        {channelRequest.feedback}
      </FormGroup>
    )
  }
}

const mapStateToProps = state => ({
  channelRequests: state.channelRequests
})

const mapDispatchToProps = dispatch => ({
  fetchChannelRequest: (appId, channelRequestId) => dispatch(
    fetchChannelRequest(appId, channelRequestId)
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(ChannelRequest)
