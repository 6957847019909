import React from 'react'
import PropTypes from 'prop-types'
import {
  H2,
  Card,
  FormGroup,
  InputGroup,
  TextArea,
  RadioGroup,
  Radio,
  Overlay
} from '@blueprintjs/core'
import ReactTags from 'react-tag-autocomplete'

import StyledButton from 'components/StyledButton'
import DeleteTagIcon from 'images/deleteTagIcon.png'

import 'styles/ChannelRequestForm.css'

class ChannelRequestForm extends React.Component {
  // TODO: pull these from a database
  tagSuggestions = [
    'Academic',
    'Academic Calendar',
    'Academic Senate',
    'Accessibility',
    'Alumni Association',
    'Arts',
    'Athletics',
    'Bank',
    'Benefits',
    'Bruin Card',
    'Bruin OnLine',
    'BruinAlert',
    'BruinBus',
    'BruinCard',
    'Bruins',
    'Calendar',
    'Camps',
    'Campus',
    'Career Center',
    'Child Care',
    'Communications',
    'Community',
    'Conference',
    'Counseling',
    'Courses',
    'Dining',
    'Directory',
    'Emergency',
    'Emeriti',
    'Engineering',
    'Events',
    'Faculty',
    'Financial Aid',
    'Garden',
    'Graduate',
    'Groups',
    'Happenings',
    'Health',
    'Help',
    'Housing',
    'Human Resources',
    'Humanities',
    'Institutes',
    'Intramural',
    'Jobs',
    'Labs',
    'LearnIT',
    'Lectures',
    'Legal',
    'LGBT',
    'Library',
    'Loan',
    'Mail',
    'Map',
    'Medical',
    'Menu',
    'Museum',
    'myUCLA',
    'News',
    'Ombuds',
    'Outdoor',
    'Parking',
    'Police',
    'Privacy',
    'Purchasing',
    'Recreation',
    'Remote Learning',
    'Remote Teaching',
    'Research',
    'Restaurants',
    'Retiree',
    'Retirement',
    'Safety',
    'Schedule of Classes',
    'Scholarship',
    'Sciences',
    'Security',
    'Social',
    'Sports',
    'Staff',
    'Store',
    'Student',
    'Summer',
    'Support',
    'Sustainability',
    'Teaching',
    'Technology',
    'Tickets',
    'Tours',
    'Transcript',
    'Transportation',
    'Travel',
    'UCLA',
    'Volunteer',
    'Wellness',
    'Workshops'
  ]

  constructor(props) {
    super(props)

    this.state = {
      sponsored: props.defaultSponsored ? true : false,
      displayName: props.defaultDisplayName,
      department: props.defaultDepartment,
      email: props.defaultEmail,
      need: props.defaultNeed,
      description: props.defaultDescription,
      tags: props.defaultTags || [],
      tagObjects: this.tagsFromStrings(props.defaultTags || []),
      tagSuggestions: this.tagsFromStrings(this.tagSuggestions),
      tagsOnly: false
    }
  }

  render() {
    return (
      <div className="ChannelRequestForm">
        <H2>
          {this.props.title}
        </H2>

        <Card>
          {this.renderInputs()}
          {this.renderNote()}
          {this.renderControls()}
        </Card>
      </div>
    )
  }

  renderInputs() {
    let displayNameLabel = 'Channel Name'
    let emailLabel = "Sponsor's Email Address"

    let departmentInput = (
      <FormGroup
        labelFor="department"
        label="Official Group or Department Name"
        labelInfo="*"
      >
        <InputGroup
          id="department"
          maxLength={50}
          defaultValue={this.props.defaultDepartment}
          onChange={this.changeInput}
        />
      </FormGroup>
    )

    let descriptionInput = (
      <FormGroup
        labelFor="description"
        label="Description (200 characters max)"
        labelInfo="*"
      >
        <TextArea
          id="description"
          maxLength={200}
          defaultValue={this.props.defaultDescription}
          onChange={this.changeInput}
        />
      </FormGroup>
    )

    if (this.state.sponsored) {
      displayNameLabel = 'Suggested Channel Name'
      emailLabel = 'Suggested Campus Sponsor'
      departmentInput = null
      descriptionInput = null
    }

    return (
      <div>
        <RadioGroup
          id="sponsored"
          name="sponsored"
          className="radio-group"
          label="Who will manage this channel?"
          selectedValue={this.state.sponsored.toString()}
          onChange={this.changeInput}
          inline
        >
          <Radio label="I will" value="false" />
          <Radio label="Suggested campus sponsor" value="true" />
        </RadioGroup>

        <FormGroup
          labelFor="displayName"
          label={displayNameLabel}
          labelInfo="*"
        >
          <InputGroup
            id="displayName"
            maxLength={50}
            defaultValue={this.props.defaultDisplayName}
            onChange={this.changeInput}
          />
        </FormGroup>

        {departmentInput}

        <FormGroup
          labelFor="email"
          label={emailLabel}
          labelInfo="*"
        >
          <InputGroup
            id="email"
            maxLength={50}
            defaultValue={this.props.defaultEmail}
            onChange={this.changeInput}
          />
        </FormGroup>

        <FormGroup
          labelFor="need"
          label="Need (200 characters max)"
          labelInfo="*"
        >
          <TextArea
            id="need"
            maxLength={200}
            defaultValue={this.props.defaultNeed}
            onChange={this.changeInput}
          />
        </FormGroup>

        {descriptionInput}
        {this.renderTagInput()}
      </div>
    )
  }

  renderTagInput() {
    const content = (
      <FormGroup
        labelFor="tags"
        label="Tags/Categories (list one to five)"
        labelInfo="*"
      >
        <ReactTags
          tags={this.state.tagObjects}
          suggestions={this.state.tagSuggestions}
          placeholder="Search for a Tag"
          noSuggestionsText="No matching tags found."
          minQueryLength={1}
          allowBackspace={false}
          autofocus={this.state.tagsOnly}
          autoresize={false}
          tagComponent={this.renderTag}
          handleFocus={this.focusTagInput}
          handleInputChange={this.adjustSuggestionsPosition}
          handleAddition={this.addTag}
          handleDelete={this.deleteTag}
        />
      </FormGroup>
    )

    if (this.state.tagsOnly) {
      return (
        <Overlay
          isOpen={true}
          autoFocus={false}
          enforceFocus={false}
          className="ChannelRequestForm"
        >
          <div>
            {content}
            <div
              className="close-overlay"
              onClick={() => this.setState({ tagsOnly: false })}
            >
              Done
            </div>
          </div>
        </Overlay>
      )
    }

    else {
      return content
    }
  }

  renderNote() {
    return (
      <p>
        Please Note: Channel requests need to be approved by the UCLA Mobile
        Steering Committee and your sponsor.
      </p>
    )
  }

  renderControls() {
    return (
      <div className="buttons">
        <StyledButton
          key="submit"
          colorClass="yellow"
          onClick={this.confirm}
        >
          Submit Request
        </StyledButton>
      </div>
    )
  }

  changeInput = (event) => {
    let id = event.currentTarget.id
    let val = event.currentTarget.value

    if (event.currentTarget.name === 'sponsored') {
      id = 'sponsored'
      val = (val === 'true')
    }

    let newState = {}
    newState[id] = val

    this.setState(newState)
  }

  renderTag = ({ tag, onDelete }) => {
    return (
      <div className="react-tag">
        {tag.name}

        <img
          src={DeleteTagIcon}
          alt="Delete Tag"
          className="react-tag-delete"
          onClick={onDelete}
        />
      </div>
    )
  }

  focusTagInput = () => {
    // If on a small screen, hide everything but tag input
    if (window.innerWidth < 768) {
      this.setState({
        tagsOnly: true
      })
    }
  }

  adjustSuggestionsPosition = () => {
    // Not needed on desktop
    if (window.innerWidth >= 768) {
      return
    }

    window.setTimeout(() => {
      const container = document.querySelector('.react-tags')
      let suggestions = document.querySelector('.react-tags__suggestions')

      if (container && suggestions) {
        suggestions.style.top = container.offsetHeight + 80 + 'px'
      }
    }, 200)
  }

  addTag = (tag) => {
    this.setState(prevState => ({
      // Add tag to active tags, then remove it from suggestions so it won't
      // show up in later searches
      tags: prevState.tags.concat(tag.id),
      tagObjects: prevState.tagObjects.concat(tag),
      tagSuggestions: prevState.tagSuggestions.filter(suggestion => (
        suggestion.id !== tag.id
      ))
    }))
  }

  deleteTag = (index) => {
    this.setState((prevState) => {
      const deletedTag = prevState.tagObjects[index]

      // Remove tag from active tags, add it back to suggestions
      return {
        tags: prevState.tags.filter((tag, i) => i !== index),
        tagObjects: prevState.tagObjects.filter((tag, i) => i !== index),
        tagSuggestions: prevState.tagSuggestions.concat(deletedTag)
      }
    })
  }

  confirm = () => {
    let message = 'Are you sure you want to submit your request?'

    if (window.confirm(message)) {
      this.props.onSubmit(this.state)
    }
  }

  tagsFromStrings(strings) {
    return strings.map(tag => ({
      id: tag,
      name: tag
    }))
  }
}

ChannelRequestForm.propTypes = {
  title: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  defaultSponsored: PropTypes.bool,
  defaultDisplayName: PropTypes.string,
  defaultDepartment: PropTypes.string,
  defaultEmail: PropTypes.string,
  defaultNeed: PropTypes.string,
  defaultDescription: PropTypes.string,
  defaultTags: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func.isRequired
}

export default ChannelRequestForm
