import React from 'react'
import PropTypes from 'prop-types'

import 'styles/AppStats.css'

class AppStats extends React.Component {
  render() {
    const columns = this.props.channels.map(channel => (
      <div key={channel.id} className="channel">
        <div>
          {channel.displayName}
        </div>
        <div className="channel-value">
          {this.props.stats[channel.id]}
        </div>
      </div>
    ))

    return (
      <div className="AppStats">
        <div className="icon">
          <img src={this.props.iconURL} alt={this.props.iconAltText} />
        </div>

        <div className="channels">
          {columns}
        </div>
      </div>
    )
  }
}

AppStats.propTypes = {
  iconURL: PropTypes.string.isRequired,
  iconAltText: PropTypes.string.isRequired,
  channels: PropTypes.arrayOf(PropTypes.object).isRequired,
  stats: PropTypes.object.isRequired
}

export default AppStats
