import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Navbar, Alignment, Button, Menu, Popover } from '@blueprintjs/core'

import StyledButton from 'components/StyledButton'
import logo from 'images/logo.png'
import Gradient from 'styles/gradient'

import 'styles/header.css'

class Header extends React.Component {
  render() {
    return (
      <header>
        <Navbar
          className="bp3-dark"
          fixedToTop
          style={{
            background: Gradient,
            height: '70px',
            paddingTop: '10px',
            paddingBottom: '10px',
            boxShadow: 'none'
          }}
        >
          <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading>
              <Link to="/">
                <img
                  src={logo}
                  alt="UCLA Mobilize Labs Messaging"
                />
              </Link>
            </Navbar.Heading>
          </Navbar.Group>

          {this.renderNavItems()}
        </Navbar>
      </header>
    )
  }

  renderNavItems() {
    // If in anonymous mode, present a login button
    if (!this.props.user) {
      return (
        <Navbar.Group align={Alignment.RIGHT}>
          <StyledButton id="login-button" colorClass="yellow" to="/login">
            Login
          </StyledButton>
        </Navbar.Group>
      )
    }

    // If in logged-in mode, show the user menu
    else {
      const roles = this.props.user.roles
      let links = []

      // Give admin users an admin link
      if (roles.includes('admin')) {
        links.push({ path: '/channel-requests', label: 'Admin' })
      }

      // Users with messaging powers see different nav links
      if (roles.length > 0) {
        links.push({ path: '/dashboard', label: 'Messaging Dashboard' })
      }

      links.push({ path: '/message-requests/new', label: 'Message Request' })
      links.push({ path: '/tutorials', label: 'Tutorials' })

      const menu = (
        <Menu>
          <Menu.Item text="Log Out" onClick={this.props.onLogout} />
        </Menu>
      )

      const menuMobile = (
        <Menu>
          {links.map(link => this.renderMenuItem(link))}
          <Menu.Item text="Log Out" onClick={this.props.onLogout} />
        </Menu>
      )

      return (
        <Navbar.Group align={Alignment.RIGHT}>
          {links.map(link => this.renderNavItem(link))}

          <Popover content={menu} className="menu-desktop">
            <Button className="bp3-minimal" rightIcon="chevron-down">
              {this.props.user.displayName}
            </Button>
          </Popover>

          <Popover content={menuMobile} className="menu-mobile">
            <Button className="bp3-minimal" rightIcon="menu" />
          </Popover>
        </Navbar.Group>
      )
    }
  }

  renderMenuItem({ path, label }) {
    return (
      <li key={path} className="bp3-popover-dismiss">
        <Link
          to={path}
          className="bp3-menu-item"
          onClick={this.closeMenu}
        >
          {label}
        </Link>
      </li>
    )
  }

  renderNavItem({ path, label }) {
    let className = 'bp3-button bp3-minimal menu-desktop'
    if (this.props.path === path) {
      className += ' active'
    }

    return (
      <Link key={path} to={path} className={className} role="button">
        <span>
          {label}
        </span>
      </Link>
    )
  }

  closeMenu = (event) => {
    // Hackily close the menu when a menu item is clicked
    // (needed because React Router interferes with normal menu operation)
    event.target.parentElement.click()
  }
}

Header.propTypes = {
  user: PropTypes.object,
  path: PropTypes.string,
  onLogout: PropTypes.func
}

export default Header
