import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { Callout } from '@blueprintjs/core'

import { logout } from 'store/keycloak'
import Header from 'components/Header'
import Footer from 'components/Footer'

class PrivateRoute extends React.Component {
  constructor() {
    super()

    this.state = {
      screen: window.screen,
      orientation: (window.screen && window.screen.orientation) || window.orientation
    }
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this.updateScreen)
  }

  render() {
    if (!this.props.user) {
      // Remember the user's origin so they can get redirected back
      sessionStorage.setItem('origin', this.props.location.pathname)

      return (
        <Redirect to="/login" />
      )
    }

    else {
      return (
        <React.Fragment>
          <Header
            user={this.props.user}
            path={this.props.path}
            onLogout={this.props.logout}
          />

          <content>
            {this.renderOrientationWarning()}
            <Route {...this.props} />
          </content>

          <Footer />
        </React.Fragment>
      )
    }
  }

  renderOrientationWarning() {
    if (!this.props.path.startsWith('/messages')) {
      return null
    }

    if (typeof this.state.screen !== 'object') {
      return null
    }

    const width = this.state.screen.width
    const orientation = this.state.orientation
    const angle = typeof orientation === 'object' ? orientation.angle : orientation

    if (width < 768 && (angle === 0 || angle === 180)) {
      return (
        <Callout intent="warning">
          This page is best viewed in landscape mode.<br />
          Please rotate your device for the best experience.
        </Callout>
      )
    }

    return null
  }

  updateScreen = (event) => {
    this.setState({
      screen: event.target.screen,
      orientation: (event.target.screen && event.target.screen.orientation) || event.target.orientation
    })
  }
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
