import titleCase from 'helpers/titleCase'

const SET_USER = 'SET_USER'
const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN'

export const setUser = user => ({
  type: SET_USER,
  user
})

export const updateUserToken = token => ({
  type: UPDATE_USER_TOKEN,
  token
})

export const user = (state = null, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...action.user,
        displayName: titleCase(action.user.displayName),
      }

    case UPDATE_USER_TOKEN:
      return {
        ...state,
        token: action.token
      }

    default:
      return state
  }
}
