import React from 'react'
import { connect } from 'react-redux'
import { H2 } from '@blueprintjs/core'

import { fetchChannelRequests } from 'store/channelRequests'
import ChannelRequestsTable from 'components/ChannelRequestsTable'

class ChannelRequestList extends React.Component {
  componentDidMount() {
    this.props.fetchChannelRequests('ucla')
  }

  render() {
    return (
      <div>
        <H2>
          Requested Channels
        </H2>

        <ChannelRequestsTable data={this.props.channelRequests} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  channelRequests: state.channelRequests
})

const mapDispatchToProps = dispatch => ({
  fetchChannelRequests: appId => dispatch(fetchChannelRequests(appId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChannelRequestList)
