const config = {
  keycloak: {
    url: 'https://bivrost.oit.ucla.edu',
    realm: 'shibboleth',
    clientId: 'messaging'
  },
  api: {
    baseUrl: 'https://msg.apps.ucla.edu/apps'
  },
  zap: {
    baseUrl: 'https://zap.ucla.edu/api/apps/',
  },
  logoutUrl: 'https://shb.ais.ucla.edu/shibboleth-idp/logout?' +
    'entityId=https%3A%2F%2Fbivrost.oit.ucla.edu%2Frealms%2Fshibboleth'
}

module.exports = config
