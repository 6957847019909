import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { selectApp, fetchAppChannels } from 'store/apps'
import { postMessage } from 'store/messages'
import { fetchZapModules } from 'store/zapModules'
import MessageForm from 'components/MessageForm'

class NewMessage extends React.Component {
  constructor(props) {
    super(props)

    // Remember the number of existing messages when the component loaded
    this.state = {
      initialMessageCount: props.messages.length
    }
  }

  componentDidMount() {
    const selected = this.props.apps.find(app => app.selected)

    if (selected) {
      this.props.fetchAppChannels(selected.id)
      this.props.fetchZapModules(selected.id)
    }
  }

  render() {
    // Any new messages? That means the submit went through, so go home.
    if (this.props.messages.length > this.state.initialMessageCount) {
      return (
        <Redirect to="/dashboard" />
      )
    }

    const selected = this.props.apps.find(app => app.selected)
    let defaultApp = ''
    let channels = []

    if (selected) {
      defaultApp = selected.id
      channels = selected.channels || []
    }

    return (
      <MessageForm
        title="Create a New Message"
        apps={this.props.apps}
        roles={this.props.user.roles}
        defaultApp={defaultApp}
        channels={channels}
        zapModules={this.props.zapModules || []}
        onChangeApp={this.onChangeApp}
        onSave={this.save}
        onSubmit={this.submit}
      />
    )
  }

  onChangeApp = (appId) => {
    this.props.selectApp(appId)
    this.props.fetchAppChannels(appId)
    this.props.fetchZapModules(appId)
  }

  save = (fields) => {
    this.props.postMessage({
      ...fields,
      send: false
    })
  }

  submit = (fields) => {
    this.props.postMessage({
      ...fields,
      send: true
    })
  }
}

const mapStateToProps = state => ({
  apps: state.apps,
  messages: state.messages,
  user: state.user,
  zapModules: state.zapModules,
})

const mapDispatchToProps = dispatch => ({
  selectApp: appId => dispatch(selectApp(appId)),
  fetchAppChannels: appId => dispatch(fetchAppChannels(appId)),
  fetchZapModules: appId => dispatch(fetchZapModules(appId)),
  postMessage: fields => dispatch(postMessage(fields)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewMessage)
