import { combineReducers, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import { apps } from 'store/apps'
import { channelRequests } from 'store/channelRequests'
import { keycloak } from 'store/keycloak'
import { messages } from 'store/messages'
import { receipts } from 'store/receipts'
import { subscription } from 'store/subscription'
import { user } from 'store/user'
import { zapModules } from 'store/zapModules'

const rootReducer = combineReducers({
  apps,
  channelRequests,
  keycloak,
  messages,
  receipts,
  subscription,
  user,
  zapModules,
})

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
)

export default store
