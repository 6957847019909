import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup, Label, Button } from '@blueprintjs/core'
import { DatePicker } from '@blueprintjs/datetime'
import moment from 'moment-timezone'

import dateIcon from 'images/scheduled.svg'
import timeIcon from 'images/time.svg'

import 'styles/DateTimePicker.css'

class DateTimePicker extends React.Component {
  // Interpret all times as this time zone, where the app "lives"
  static appTimeZone = 'America/Los_Angeles'

  // Acceptable formats for manual date entry
  static validDateFormats = [
    'MM/DD/YYYY H:m',
    'M/DD/YYYY H:m',
    'MM/D/YYYY H:m',
    'M/D/YYYY H:m'
  ]

  constructor(props) {
    super(props)

    let manualInputDate = ''
    let userCentricDate = null
    let appCentricMoment = null

    // Handle default timestamp, convert to moment/dates, and propagate parent state
    if (props.defaultValue) {
      appCentricMoment = moment.tz(props.defaultValue, DateTimePicker.appTimeZone)
      userCentricDate = new Date(appCentricMoment.format('YYYY-MM-DD HH:mm:ss'))
      manualInputDate = appCentricMoment.format('MM/DD/YYYY')

      if (this.props.onChange) {
        this.props.onChange(appCentricMoment.valueOf())
      }
    }

    this.state = {
      datePickerVisible: false,
      manualInputDate,
      userCentricDate,
      appCentricMoment
    }

    // Handle all clicks while this component is in the DOM
    document.addEventListener('mousedown', this.handleClick, false)
  }

  // No need to keep checking clicks, clean up
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  render() {
    const dateInputId = this.props.id + 'Date'
    const defaultTime = new Date(0, 0, 0, 0, 0)

    let className = 'DateTimePicker'
    if (this.state.datePickerVisible) {
      className += ' date-picker-visible'
    }

    const toggleButton = (
      <Button minimal icon="chevron-down" onClick={this.toggleDatePicker} />
    )

    const dateLabel = (
      <span className="label-icon">
        <img src={dateIcon} alt="" />
        {this.props.label}
      </span>
    )

    const timeLabel = (
      <span className="label-icon">
        <img src={timeIcon} alt="" />
        Time
      </span>
    )

    return (
      <div className={className} ref={node => this.node = node}>
        <FormGroup inline label={dateLabel} labelFor={dateInputId}>
          <InputGroup
            id={dateInputId}
            rightElement={toggleButton}
            placeholder="MM/DD/YYYY"
            value={this.state.manualInputDate}
            onChange={this.onManualDateChange}
          />
          <DatePicker
            value={this.state.userCentricDate}
            onChange={this.onPickDate}
            timePickerProps={{ useAmPm: true, defaultValue: defaultTime }}
          />
        </FormGroup>

        <FormGroup inline label={timeLabel}>
          <Label style={{ marginLeft: '160px' }}>
            Pacific Time
          </Label>
        </FormGroup>
      </div>
    )
  }

  handleClick = (event) => {
    // If the date picker isn't open, there's nothing to do
    if (!this.state.datePickerVisible) {
      return
    }

    // This includes the date picker itself and its text input
    const datePickerArea = this.node.querySelector('.bp3-form-content')

    // If the click was outside the designated area, close the date picker
    if (datePickerArea && !datePickerArea.contains(event.target)) {
      this.toggleDatePicker()
    }
  }

  toggleDatePicker = () => {
    this.setState(prevState => ({
      datePickerVisible: !prevState.datePickerVisible
    }))
  }

  onPickDate = (date, isUserChange) => {
    // Ignore non-user-initiated changes, like month/year navigation
    if (!isUserChange) {
      return
    }

    // Take the user-entered datetime but stamp it with the app's time zone
    let newMoment = moment(date)
    newMoment.tz(DateTimePicker.appTimeZone, true)

    // Update the manual date entry field as well as internal state
    this.setState({
      manualInputDate: newMoment.format('MM/DD/YYYY'),
      userCentricDate: date,
      appCentricMoment: newMoment
    }, () => {
      if (this.props.onChange) {
        this.props.onChange(newMoment.valueOf())
      }
    })
  }

  onManualDateChange = (event) => {
    const val = event.currentTarget.value
    let propagateTimestamp = null
    let newState = { manualInputDate: val }

    this.setState((prevState) => {
      let hours = 0
      let minutes = 0

      // Pull in time from user-entered picker if it was used
      if (prevState.userCentricDate) {
        hours = prevState.userCentricDate.getHours()
        minutes = prevState.userCentricDate.getMinutes()
      }

      // Try to parse the date according to a strict format in the user's TZ
      const userMoment = moment(
        val + ' ' + hours + ':' + minutes,
        DateTimePicker.validDateFormats,
        true
      )
      const userDate = userMoment.toDate()

      // If the new value is a valid date and in the future...
      if (userMoment.isValid() && userDate > new Date()) {
        // Let the picker still think this is in the user's time zone
        newState.userCentricDate = userDate

        // But really the internal state is set to the app's time zone
        let newMoment = userMoment.clone()
        newMoment.tz(DateTimePicker.appTimeZone, true)
        newState.appCentricMoment = newMoment

        // And also propagate the change
        propagateTimestamp = newMoment.valueOf()
      }

      return newState
    }, () => {
      if (propagateTimestamp && this.props.onChange) {
        this.props.onChange(propagateTimestamp)
      }
    })
  }
}

DateTimePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func
}

export default DateTimePicker
