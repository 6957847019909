import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { H1 } from '@blueprintjs/core'

import { initKeycloak } from 'store/keycloak'
import { fetchApps } from 'store/apps'
import Status from 'components/Status'

class Login extends React.Component {
  componentDidMount() {
    if (!this.props.user) {
      this.props.fetchApps()
      this.props.initKeycloak()
    }
  }

  render() {
    const origin = sessionStorage.getItem('origin')

    // Render loading screen if auth/app state is unknown
    if (this.props.apps.length < 1 || !this.props.user) {
      return (
        <Status>
          <H1>
            Welcome to Messaging
          </H1>

          <p style={{ fontSize: '1.5rem' }}>
            Loading, please wait...
          </p>
        </Status>
      )
    }

    // Return users to their pre-login origin if there is one
    else if (origin) {
      sessionStorage.removeItem('origin')

      return (
        <Redirect to={origin} />
      )
    }

    // Users with push access default to the Dashboard
    else if (this.props.user.roles.length > 0) {
      return (
        <Redirect to="/dashboard" />
      )
    }

    // All other users default to the Message Request page
    else {
      return (
        <Redirect to="/message-requests/new" />
      )
    }
  }
}

const mapStateToProps = state => ({
  apps: state.apps,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  fetchApps: () => dispatch(fetchApps()),
  initKeycloak: () => dispatch(initKeycloak())
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
