import config from 'config'

const SET_RECEIPTS = 'SET_RECEIPTS'

export const setReceipts = receipts => ({
  type: SET_RECEIPTS,
  receipts
})

export const receipts = (state = {}, action) => {
  switch (action.type) {
    case SET_RECEIPTS:
      return action.receipts

    default:
      return state
  }
}

export const fetchReceipts = appId => async (dispatch, state) => {
  const url = config.api.baseUrl + '/' + appId + '/receipts'
  const response = await fetch(url)

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    const json = await response.json()
    dispatch(setReceipts(json))
  }
}
