import config from 'config'

const SET_APPS = 'SET_APPS'
const SET_APP_STATS = 'SET_APP_STATS'
const SET_APP_CHANNELS = 'SET_APP_CHANNELS'
const SELECT_APP = 'SELECT_APP'

export const setApps = apps => ({
  type: SET_APPS,
  apps
})

export const setAppChannels = (appId, channels) => ({
  type: SET_APP_CHANNELS,
  appId,
  channels
})

export const setAppStats = (appId, stats) => ({
  type: SET_APP_STATS,
  appId,
  stats
})

export const selectApp = (appId) => ({
  type: SELECT_APP,
  appId
})

export const apps = (state = [], action) => {
  switch (action.type) {
    case SET_APPS:
      return action.apps

    case SET_APP_CHANNELS:
      return state.map((app) => {
        if (app.id === action.appId) {
          return {
            ...app,
            channels: action.channels
          }
        }
        else {
          return app
        }
      })

    case SET_APP_STATS:
      return state.map((app) => {
        if (app.id === action.appId) {
          return {
            ...app,
            stats: action.stats
          }
        }
        else {
          return app
        }
      })

    case SELECT_APP:
      return state.map((app) => ({
        ...app,
        selected: app.id === action.appId
      }))

    default:
      return state
  }
}

export const fetchApps = () => async (dispatch, state) => {
  const url = config.api.baseUrl + '/'
  const response = await fetch(url)

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    const json = await response.json()
    dispatch(setApps(json))
  }
}

export const fetchAppChannels = appId => async (dispatch, state) => {
  const url = config.api.baseUrl + '/' + appId + '/channels?all=1'
  const response = await fetch(url)

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    let json = await response.json()
    const roles = state().user.roles

    // If the user doesn't have app-wide access,
    // filter out channels they don't have access to
    if (!roles.includes(appId)) {
      const allowedChannels = roles.filter(role => (
        role.startsWith(appId + ':')
      )).map(channel => channel.replace(appId + ':', ''))

      json = json.filter(channel => allowedChannels.includes(channel.id))
    }

    dispatch(setAppChannels(appId, json))
  }
}

export const fetchAppChannelsUnfiltered = appId => async (dispatch, state) => {
  const url = config.api.baseUrl + '/' + appId + '/channels?all=1'
  const response = await fetch(url)

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    let json = await response.json()
    dispatch(setAppChannels(appId, json))
  }
}

export const fetchAppStats = appId => async (dispatch, state) => {
  const url = config.api.baseUrl + '/' + appId + '/stats'
  const token = state().user.token

  const response = await fetch(url, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    const json = await response.json()
    dispatch(setAppStats(appId, json))
  }
}
