import config from 'config'
import { setUser, updateUserToken } from 'store/user'

const SET_KEYCLOAK = 'SET_KEYCLOAK'

export const setKeycloak = keycloak => ({
  type: SET_KEYCLOAK,
  keycloak
})

export const keycloak = (state = null, action) => {
  switch (action.type) {
    case SET_KEYCLOAK:
      return action.keycloak

    default:
      return state
  }
}

export const initKeycloak = () => (dispatch, state) => {
  const kc = window.Keycloak(config.keycloak)

  kc.init({ checkLoginIframe: false }).success(authenticated => {
    if (authenticated) {
      // Refresh token as needed
      window.setInterval(() => {
        kc.updateToken().success((refreshed) => {
          if (refreshed) {
            dispatch(updateUserToken(kc.token))
          }
        }).error(() => {
          let expireTime = 0
          if (kc.refreshTokenParsed && kc.refreshTokenParsed.exp) {
            expireTime = kc.refreshTokenParsed.exp * 1000
          }

          // This isn't necessarily a permanent error (maybe network hiccup)
          // But an expired refresh token is fatal, we must log them out
          if (expireTime < Date.now()) {
            window.alert('You have been logged out due to inactivity.')
            window.location.reload()
          }
        })
      }, 60000)

      let roles = []
      if (kc.resourceAccess.messaging) {
        roles = kc.resourceAccess.messaging.roles || []
      }

      dispatch(setKeycloak(kc))
      dispatch(setUser({
        token: kc.token,
        username: kc.tokenParsed.preferred_username,
        displayName: kc.tokenParsed.name,
        roles,
      }))
    }

    // Redirect to login page if not authenticated
    else {
      kc.login({ idpHint: 'ucla' })
    }
  })
}

export const logout = () => (dispatch, state) => {
  // Fully log out of Keycloak and expire the Shib session
  // This is a redirect, so Redux state will get cleared as well
  state().keycloak.logout({ redirectUri: config.logoutUrl })
}
