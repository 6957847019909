import React from 'react'
import PropTypes from 'prop-types'
import { HTMLSelect } from '@blueprintjs/core'

import Colors from 'styles/colors'

import 'styles/AppDropdown.css'

class AppDropdown extends React.Component {
  render() {
    const authorizedApps = this.props.apps.filter(app => (
      this.props.roles.some(role => (
        role === app.id || role.startsWith(app.id + ':')
      ))
    ))

    // Don't render anything unless there is more than 1 app to choose from
    if (authorizedApps.length <= 1) {
      return null
    }

    const options = authorizedApps.map(app => (
      <option key={app.id} value={app.id}>
        {app.displayName || app.id}
      </option>
    ))

    const selected = authorizedApps.find(app => app.selected)
    const defaultValue = selected ? selected.id : ''

    return (
      <HTMLSelect
        id={this.props.id}
        className="AppDropdown"
        iconProps={{ icon: 'chevron-down', color: Colors.blue }}
        fill
        defaultValue={defaultValue}
        onChange={this.props.onChange}
      >
        <option key="" value="" disabled>
          Select an app
        </option>

        {options}
      </HTMLSelect>
    )
  }
}

AppDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  apps: PropTypes.arrayOf(PropTypes.object).isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func
}

export default AppDropdown
