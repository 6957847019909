import React from 'react'

import Footer from 'components/Footer'
import mobilizeLabs from 'images/mobilizeLabs.svg'

import 'styles/Status.css'

class Status extends React.Component {
  render() {
    return (
      <div className="Status">
        <div className="card">
          <img
            src={mobilizeLabs}
            alt="UCLA Mobilize Labs"
            style={{ width: '50%', marginBottom: '10px' }}
          />

          {this.props.children}
        </div>

        <Footer light />
      </div>
    )
  }
}

export default Status
