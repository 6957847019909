import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from '@blueprintjs/core'

import 'styles/buttons.css'

class StyledButton extends React.Component {
  render() {
    // Set default props but allow overrides
    let props = {
      className: 'bp3-button bp3-minimal styled-button',
      ...this.props
    }

    // Additional color behavior (must appear in index.css to work!)
    if (this.props.colorClass) {
      props.className += ' styled-button-' + this.props.colorClass
    }

    // Get rid of warning about custom DOM props
    delete props.colorClass

    // If there is a React-Router "to" prop, render as a link
    if (this.props.to) {
      return (
        <Link {...props}>
          {this.props.children}
        </Link>
      )
    }

    // Otherwise render as a button
    else {
      return (
        <Button {...props}>
          {this.props.children}
        </Button>
      )
    }
  }
}

StyledButton.propTypes = {
  colorClass: PropTypes.oneOf(['yellow', 'gray'])
}

export default StyledButton
