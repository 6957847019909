import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Icon } from '@blueprintjs/core'
import ReactTable from 'react-table'
import ReactPaginate from 'react-paginate'
import moment from 'moment'

import titleCase from 'helpers/titleCase'

import 'react-table/react-table.css'
import 'styles/pagination.css'
import 'styles/tables.css'
import 'styles/tabs.css'

class ChannelRequestsTable extends React.Component {
  state = {
    page: 0
  }

  render() {
    const pageSize = 10
    const pageCount = Math.ceil(this.props.data.length / pageSize)
    const leftArrow = <Icon icon="chevron-left" iconSize={20} />
    const rightArrow = <Icon icon="chevron-right" iconSize={20} />

    return (
      <div>
        <ReactTable
          columns={this.getColumns()}
          data={this.props.data}
          minRows={1}
          noDataText="No Channel Requests"
          defaultPageSize={pageSize}
          showPagination={false}
          page={this.state.page}
          defaultSorted={[{ id: 'id', desc: true }]}
          defaultSortDesc={true}
        />

        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          previousLabel={leftArrow}
          nextLabel={rightArrow}
          containerClassName="pagination"
          onPageChange={this.changePage}
        />
      </div>
    )
  }

  changePage = (data) => {
    this.setState({
      page: data.selected
    })
  }

  getColumns() {
    const columns = [{
      Header: this.sortableHeader('Request #'),
      accessor: 'id',
      Cell: this.linkToChannelRequest,
      width: 150
    }, {
      Header: this.sortableHeader('Status'),
      accessor: 'status'
    }, {
      Header: this.sortableHeader('Channel Name'),
      accessor: 'displayName'
    }, {
      Header: this.sortableHeader('Requester'),
      accessor: 'requester',
      Cell: props => titleCase(props.value)
    }, {
      Header: this.sortableHeader('Requested Date'),
      accessor: 'requestedAt',
      Cell: props => this.formattedTime(props, '')
    }, {
      Header: this.sortableHeader('Reviewed Date'),
      accessor: 'reviewedAt',
      Cell: props => this.formattedTime(props, '')
    }]

    return columns
  }

  sortableHeader = (text) => {
    return (
      <span>
        {text}
        <Icon icon="chevron-down" />
        <Icon icon="chevron-up" />
      </span>
    )
  }

  linkToChannelRequest = (props) => {
    const url = '/channel-requests/' + props.value

    return (
      <Link to={url}>
        {props.value}
      </Link>
    )
  }

  formattedTime(props, defaultValue) {
    // Display the default value if no date was given
    if (!props.value) {
      return defaultValue
    }

    else {
      return moment(props.value).format('MM/DD/YY h:mma')
    }
  }
}

ChannelRequestsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object)
}

export default ChannelRequestsTable
