import React from 'react'
import PropTypes from 'prop-types'
import {
  H2,
  Card,
  FormGroup,
  Checkbox,
  InputGroup,
  RadioGroup,
  Radio,
  MenuItem,
} from '@blueprintjs/core'
import { Suggest } from '@blueprintjs/select'

import AppDropdown from 'components/AppDropdown'
import DateTimePicker from 'components/DateTimePicker'
import StyledButton from 'components/StyledButton'

import 'styles/MessageForm.css'

const nullScreen = { _id: '', title: 'Select a Screen' }

class MessageForm extends React.Component {
  constructor(props) {
    super(props)

    let channels = {}
    if (props.defaultChannels) {
      props.defaultChannels.forEach((channel) => {
        channels[channel] = true
      })
    }

    let link = ''
    let screen

    if (props.defaultUrl) {
      link = 'url'
    }
    else if (props.defaultScreen) {
      link = 'screen'
      screen = this.getScreenById(props.defaultScreen)
    }

    this.state = {
      app: props.defaultApp || '',
      channels,
      title: props.defaultTitle || '',
      body: props.defaultBody || '',
      link,
      url: props.defaultUrl || '',
      silent: props.defaultSilent || false,
      screen: screen || nullScreen,
      scheduled: null,
      expires: null
    }
  }

  render() {
    return (
      <div className="MessageForm">
        <H2>
          {this.props.title}
        </H2>

        <Card>
          {this.renderAppSelect()}
          {this.renderChannelSelect()}
          {this.renderTextInputs()}
          {this.renderControls()}
        </Card>
      </div>
    )
  }

  renderAppSelect() {
    // Don't render a dropdown unless there is more than 1 app to choose from
    if (this.props.apps.length <= 1) {
      return (
        <FormGroup label="App">
          <p>
            {this.props.apps[0].displayName}
          </p>
        </FormGroup>
      )
    }

    return (
      <FormGroup labelFor="app" label="Select App" style={{ width: '33%' }}>
        <AppDropdown
          id="app"
          defaultValue={this.props.defaultApp || ''}
          onChange={this.changeInput}
          apps={this.props.apps}
          roles={this.props.roles}
        />
      </FormGroup>
    )
  }

  renderChannelSelect() {
    let checkboxes = []

    if (this.props.channels.length < 1) {
      return null
    }

    this.props.channels.forEach((channel, i) => {
      let disabled = false

      if (i && i % 4 === 0) {
        checkboxes.push(<br key={i} />)
      }

      if (channel.id !== 'all' && this.state.channels.all) {
        disabled = true
      }
      else if (
        channel.id !== 'staging-all' &&
        this.state.channels['staging-all']
      ) {
        disabled = true
      }
      else if (
        channel.id !== 'all' &&
        channel.id !== 'staging-all' &&
        !this.state.channels[channel.id] &&
        Object.keys(this.state.channels).length >= 5
      ) {
        disabled = true
      }

      checkboxes.push(
        <Checkbox
          key={channel.id}
          value={channel.id}
          checked={this.state.channels[channel.id] || false}
          disabled={disabled}
          label={channel.displayName || channel.id}
          inline
          large
          onChange={this.changeChannels}
        />
      )
    })

    return (
      <FormGroup label="Select Channels (up to 5)">
        <fieldset>
          {checkboxes}
        </fieldset>
      </FormGroup>
    )
  }

  renderTextInputs() {
    if (!this.state.app) {
      return null
    }

    return (
      <div>
        <FormGroup labelFor="title" label="Message Title (optional)">
          <InputGroup
            id="title"
            defaultValue={this.props.defaultTitle}
            onChange={this.changeInput}
          />
        </FormGroup>

        <FormGroup labelFor="body" label="Message Text">
          <InputGroup
            id="body"
            defaultValue={this.props.defaultBody}
            onChange={this.changeInput}
          />
        </FormGroup>

        {this.renderLinkInputs()}

        <div className={'link-options show-' + this.state.link}>
          <div />
          {this.renderUrlInput()}
          {this.renderScreenSelect()}
        </div>

        {this.renderSilentCheckbox()}

        <FormGroup label="Schedule">
          <p>
            Please note: Messages will go out after the scheduled time.
            Delivery time variable depending on carrier.
          </p>
        </FormGroup>

        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <DateTimePicker
            id="begin"
            label="Begin Date"
            defaultValue={this.props.defaultScheduled}
            onChange={this.changeScheduled}
          />
          <DateTimePicker
            id="expire"
            label="Expire Date"
            defaultValue={this.props.defaultExpires}
            onChange={this.changeExpires}
          />
        </div>
      </div>
    )
  }

  renderLinkInputs() {
    let zapRadio

    // Hack: Moodle doesn't get these options
    if (this.state.app === 'edu.ucla.ccle.moodlemobile') {
      return null
    }

    if (this.props.zapModules.length > 0) {
      zapRadio = (
        <Radio key="screen" value="screen" label="ZAP View" />
      )
    }

    return (
      <FormGroup label="Add Link (optional)" style={{ marginBottom: 0 }}>
        <p>
          Would you like to link to a URL or ZAP View?
        </p>

        <RadioGroup
          inline
          selectedValue={this.state.link}
          onChange={this.changeLink}
          className="link-options"
        >
          <Radio key="none" value="" label="None" />
          <Radio key="url" value="url" label="Landing Page URL" />
          {zapRadio}
        </RadioGroup>
      </FormGroup>
    )
  }

  renderUrlInput() {
    return (
      <InputGroup
        id="url"
        className="url-input"
        defaultValue={this.props.defaultUrl}
        onChange={this.changeInput}
      />
    )
  }

  renderScreenSelect() {
    const noResults = (
      <MenuItem disabled={true} text="No results." />
    )

    if (this.props.zapModules.length < 1) {
      return null
    }

    return (
      <Suggest
        items={[ nullScreen ].concat(this.props.zapModules)}
        itemsEqual="_id"
        itemPredicate={this.filterScreens}
        itemRenderer={this.renderScreenItem}
        noResults={noResults}
        defaultSelectedItem={this.getScreenById(this.props.defaultScreen)}
        inputValueRenderer={this.getScreenTitle}
        onItemSelect={(screen) => this.setState({ screen })}
        className="screen-input"
        resetOnSelect
        fill
      />
    )
  }

  filterScreens = (query, screen, _index, exactMatch) => {
    const normalizedTitle = this.getScreenTitle(screen).toLowerCase()
    const normalizedQuery = query.toLowerCase()

    if (!screen._id) {
      return false
    }
    else if (exactMatch) {
      return normalizedTitle === normalizedQuery
    }
    else {
      return normalizedTitle.includes(normalizedQuery)
    }
  }

  renderScreenItem = (screen, props) => {
    if (!props.modifiers.matchesPredicate) {
      return null
    }

    return (
      <MenuItem
        {...this.getScreenItemProps(screen, props)}
      />
    )
  }

  getScreenItemProps = (screen, { handleClick, handleFocus, modifiers, query, ref }) => {
    return {
      key: screen._id,
      ref,
      text: this.getScreenTitle(screen) || '(no title)',
      active: screen._id === this.state.screen._id,
      onClick: handleClick,
      onFocus: handleFocus,
    }
  }

  getScreenById(id) {
    return this.props.zapModules.find((module) => module._id === id)
  }

  getScreenTitle = (screen) => screen.hiddenTitle || screen.title

  renderSilentCheckbox() {
    // Temporarily disable this option until it's working in apps
    return null

    return (
      <FormGroup label="Send Silently (optional)">
        <p>
          Users will not see a notification at the top of their screen,
          but it will appear in the Notifications screen of the app.<br />
          App version 3.3 or later will also get a numbered badge on the
          Notifications tab.
        </p>

        <Checkbox
          id="silent"
          defaultChecked={this.state.silent}
          onChange={this.changeSilent}
          label="Silent"
          inline
          large
        />
      </FormGroup>
    )
  }

  renderControls() {
    let cancelUrl = '/'
    if (this.state.app) {
      cancelUrl = '/dashboard/' + this.state.app
    }

    // Cancel button is always available
    let buttons = [(
      <StyledButton
        key="cancel"
        colorClass="gray"
        to={cancelUrl}
        style={{ width: '180px', marginLeft: '20px' }}
      >
        Cancel
      </StyledButton>
    )]

    // Clone button is available for existing messages
    if (this.props.onClone) {
      buttons.push(
        <StyledButton
          key="clone"
          onClick={this.clone}
          style={{ width: '180px', marginLeft: '20px' }}
        >
          Clone
        </StyledButton>
      )
    }

    // Other buttons are only present if an app is selected
    if (this.state.app && !this.props.readOnly) {
      buttons.push(
        <StyledButton
          key="save"
          onClick={this.save}
          style={{ width: '180px', marginLeft: '20px' }}
        >
          Save
        </StyledButton>
      )

      buttons.push(
        <StyledButton
          key="submit"
          colorClass="yellow"
          onClick={this.confirm}
          style={{ width: '180px', marginLeft: '20px' }}
        >
          Send Message
        </StyledButton>
      )
    }

    return (
      <div style={{ marginTop: '280px', textAlign: 'right' }}>
        {buttons}
      </div>
    )
  }

  changeInput = (event) => {
    const id = event.currentTarget.id
    const val = event.currentTarget.value

    let newState = {}
    newState[id] = val

    // If changing the app, clear out the selected channels/modules
    // and ask the container to fetch the new stuff
    if (id === 'app') {
      newState.channels = {}
      newState.link = ''
      this.props.onChangeApp(val)
    }

    this.setState(newState)
  }

  changeChannels = (event) => {
    const channel = event.currentTarget.value
    const checked = event.currentTarget.checked

    this.setState((prevState) => {
      let newState = Object.assign(prevState)

      // If sending to all devices, uncheck all other channels
      if (checked && channel === 'all') {
        newState.channels = { all: true }
      }
      else if (checked && channel === 'staging-all') {
        newState.channels = { 'staging-all': true }
      }
      else if (checked) {
        newState.channels[channel] = true
      }
      else {
        delete newState.channels[channel]
      }

      return newState
    })
  }

  changeLink = (event) => {
    this.setState({
      link: event.currentTarget.value
    })
  }

  changeSilent = (event) => {
    const checked = event.currentTarget.checked

    this.setState({
      silent: checked
    })
  }

  changeScheduled = (timestamp) => {
    this.setState({
      scheduled: timestamp
    })
  }

  changeExpires = (timestamp) => {
    this.setState({
      expires: timestamp
    })
  }

  confirm = () => {
    let message = 'Are you sure that you would like to '
    message += this.state.scheduled ? 'schedule' : 'send'
    message += ' this message?'

    if (window.confirm(message)) {
      this.props.onSubmit({
        ...this.state,
        screen: this.state.screen._id,
      })
    }
  }

  save = () => {
    this.props.onSave({
      ...this.state,
      screen: this.state.screen._id,
    })
  }

  clone = () => {
    this.props.onClone({
      ...this.state,
      screen: this.state.screen._id,
    })
  }
}

MessageForm.propTypes = {
  title: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  apps: PropTypes.arrayOf(PropTypes.object).isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  channels: PropTypes.arrayOf(PropTypes.object).isRequired,
  zapModules: PropTypes.arrayOf(PropTypes.object),
  defaultApp: PropTypes.string,
  defaultChannels: PropTypes.arrayOf(PropTypes.string),
  defaultTitle: PropTypes.string,
  defaultBody: PropTypes.string,
  defaultUrl: PropTypes.string,
  defaultScreen: PropTypes.string,
  defaultSilent: PropTypes.bool,
  defaultScheduled: PropTypes.number,
  defaultExpires: PropTypes.number,
  onChangeApp: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClone: PropTypes.func
}

export default MessageForm
