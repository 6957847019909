import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Icon } from '@blueprintjs/core'
import ReactTable from 'react-table'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import ReactPaginate from 'react-paginate'
import moment from 'moment'

import DeleteButton from 'components/DeleteButton'
import titleCase from 'helpers/titleCase'

import 'react-table/react-table.css'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import 'styles/pagination.css'
import 'styles/tables.css'
import 'styles/tabs.css'
import savedIcon from 'images/saved.svg'
import scheduledIcon from 'images/scheduled.svg'

const ReactTableFixedColumns = withFixedColumns(ReactTable)

class MessagesTable extends React.Component {
  // Interpret all times as this time zone, where the app "lives"
  static appTimeZone = 'America/Los_Angeles'

  constructor() {
    super()

    this.state = {
      page: 0
    }
  }

  render() {
    const pageSize = 10
    const pageCount = Math.ceil(this.props.data.length / pageSize)
    const leftArrow = <Icon icon="chevron-left" iconSize={20} />
    const rightArrow = <Icon icon="chevron-right" iconSize={20} />

    return (
      <div>
        <ReactTableFixedColumns
          columns={this.getColumns()}
          data={this.props.data}
          minRows={1}
          noDataText="No Messages"
          defaultPageSize={pageSize}
          showPagination={false}
          page={this.state.page}
          defaultSorted={[{ id: 'id', desc: true }]}
          defaultSortDesc={true}
        />

        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          previousLabel={leftArrow}
          nextLabel={rightArrow}
          containerClassName="pagination"
          onPageChange={this.changePage}
        />
      </div>
    )
  }

  changePage = (data) => {
    this.setState({
      page: data.selected
    })
  }

  getColumns() {
    let msgDate = 'Msg Date'
    let msgDateAccessor = 'sent'

    // Scheduled messages use a different column
    if (this.props.type === 'pending') {
      msgDate = 'Scheduled Msg Date'
      msgDateAccessor = 'scheduled'
    }

    let columns = [{
      Header: this.sortableHeader('Msg #'),
      accessor: 'id',
      Cell: this.linkToMessage,
      width: 110,
      fixed: 'left'
    }, {
      Header: this.sortableHeader('Message'),
      accessor: 'body'
    }, {
      Header: this.sortableHeader('Channels'),
      accessor: 'channels',
      Cell: this.displayChannels,
      width: 150,
      style: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        lineHeight: 'normal'
      }
    }]

    if (this.props.type === 'pending') {
      columns.push({
        Header: this.sortableHeader('Status'),
        accessor: 'status',
        Cell: this.displayStatus,
        width: 150
      })
    }

    columns.push({
      Header: this.sortableHeader('Sender'),
      accessor: 'sender',
      Cell: props => titleCase(props.value)
    }, {
      Header: this.sortableHeader(msgDate),
      accessor: msgDateAccessor,
      Cell: props => this.formattedTime(props, ''),
      width: 240
    }, {
      Header: this.sortableHeader('Msg Exp'),
      accessor: 'expires',
      Cell: props => this.formattedTime(props, 'No'),
      width: 160
    })

    if (this.props.type === 'pending') {
      columns.push({
        Header: 'Delete',
        accessor: 'messageId',
        Cell: this.deleteButton,
        sortable: false,
        width: 100,
        style: { textAlign: 'center' }
      })
    }

    // Hack because Moodle doesn't allow read receipts
    else if (this.props.app !== 'edu.ucla.ccle.moodlemobile') {
      columns.push({
        Header: this.sortableHeader('# Reached'),
        id: 'reached',
        accessor: row => this.props.receipts[row.messageId] || 0,
        width: 150
      })
    }

    return columns
  }

  sortableHeader = (text) => {
    return (
      <span>
        {text}
        <Icon icon="chevron-down" />
        <Icon icon="chevron-up" />
      </span>
    )
  }

  linkToMessage = (props) => {
    const url = '/messages/' + props.original.messageId

    return (
      <Link to={url}>
        {props.value}
      </Link>
    )
  }

  displayChannels = (props) => {
    return props.value.map(id => (
      <div key={id}>
        {this.props.channels[id]}
      </div>
    ))
  }

  displayStatus = (props) => {
    const icon = props.value === 'saved' ? savedIcon : scheduledIcon

    return (
      <span>
        <img src={icon} className="status-icon" alt="" />
        {titleCase(props.value)}
      </span>
    )
  }

  formattedTime(props, defaultValue) {
    // Display the default value if no date was given
    if (!props.value) {
      return defaultValue
    }

    else {
      const appMoment = moment.tz(props.value, MessagesTable.appTimeZone)
      return appMoment.format('MM/DD/YY h:mma PT')
    }
  }

  deleteButton = (props) => {
    // Only allow delete if the message creator is the current user
    if (props.original.senderUsername === this.props.user.username) {
      return (
        <DeleteButton id={props.value} onDelete={this.props.onDeleteMessage} />
      )
    }

    else {
      return null
    }
  }
}

MessagesTable.propTypes = {
  app: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['pending', 'sent']).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  channels: PropTypes.object,
  receipts: PropTypes.object,
  onDeleteMessage: PropTypes.func
}

export default MessagesTable
