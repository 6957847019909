import config from 'config'

const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'

export const setSubscription = subscription => ({
  type: SET_SUBSCRIPTION,
  subscription
})

export const subscription = (state = {}, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION:
      return action.subscription

    default:
      return state
  }
}

export const subscribe = (appId, formData) => async (dispatch, state) => {
  let url = config.api.baseUrl + '/' + appId + '/carriers/' + formData.method

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData)
  })

  if (!response.ok) {
    window.alert(await response.text())
  }

  else {
    const json = await response.json()
    dispatch(setSubscription(json))
  }
}

export const verify = (appId, formData) => async (dispatch, state) => {
  let url = config.api.baseUrl + '/' + appId + '/carriers/'
  url += formData.method + '/' + formData.address + '/verify'

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData)
  })

  if (!response.ok) {
    let message = 'The '

    if (formData.method === 'email') {
      message += 'email address'
    }
    else {
      message += 'phone number'
    }

    message += ' or code you entered is invalid, or the code has expired.'

    window.alert(message)
  }

  else {
    const json = await response.json()
    dispatch(setSubscription(json))
  }
}

export const fetchSubscribedChannels = (appId, formData) => async (dispatch, state) => {
  let url = config.api.baseUrl + '/' + appId + '/carriers/'
  url += formData.method + '/' + formData.address + '?code=' + formData.code

  const response = await fetch(url)

  if (!response.ok) {
    let message = 'The '

    if (formData.method === 'email') {
      message += 'email address'
    }
    else {
      message += 'phone number'
    }

    message += ' or code you entered is invalid.'

    window.alert(message)
  }

  else {
    const json = await response.json()
    dispatch(setSubscription(json))
  }
}

export const updateSubscribedChannels = (appId, formData) => async (dispatch, state) => {
  let url = config.api.baseUrl + '/' + appId + '/carriers/'
  url += formData.method + '/' + formData.address

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData)
  })

  if (!response.ok) {
    let message = 'The '

    if (formData.method === 'email') {
      message += 'email address'
    }
    else {
      message += 'phone number'
    }

    message += ' or code you entered is invalid.'

    window.alert(message)
  }

  else {
    const json = await response.json()
    dispatch(setSubscription(json))
  }
}

export const unsubscribe = (appId, formData) => async (dispatch, state) => {
  let url = config.api.baseUrl + '/' + appId + '/carriers/'
  url += formData.method + '/' + formData.address

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData)
  })

  if (!response.ok) {
    let message = 'The '

    if (formData.method === 'email') {
      message += 'email address'
    }
    else {
      message += 'phone number'
    }

    message += ' or code you entered is invalid.'

    window.alert(message)
  }

  else {
    const json = await response.json()
    dispatch(setSubscription(json))
  }
}
