import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { selectApp, fetchAppChannels } from 'store/apps'
import { fetchMessage, postMessage } from 'store/messages'
import { fetchZapModules } from 'store/zapModules'
import MessageForm from 'components/MessageForm'

class EditMessage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      messageId: props.match.params.id
    }
  }

  componentDidMount() {
    const selected = this.props.apps.find(app => app.selected)

    this.props.fetchAppChannels(selected.id)
    this.props.fetchZapModules(selected.id)
    this.props.fetchMessage(selected.id, this.state.messageId)
  }

  render() {
    // Kinda hacky, but check for a certain flag to know when to redirect home
    const newMessage = this.props.messages.find(message => message.redirect)
    if (newMessage) {
      return (
        <Redirect to={newMessage.redirect} />
      )
    }

    // Also kinda hacky, wait for ZAP modules list to be ready
    if (this.props.zapModules === null) {
      return null
    }

    const selected = this.props.apps.find(app => app.selected)
    const loadedMessage = this.props.messages.find(message => (
      message.messageId === this.state.messageId
    ))

    if (!loadedMessage || !selected.channels) {
      return null
    }

    let title = 'Saved Message Draft'
    let readOnly = false

    // If message was already sent, the form is read-only
    if (loadedMessage.status === 'sent') {
      title = 'Sent Message'
      readOnly = true
    }

    // If the owner of the message isn't the current user, it's read-only
    else if (loadedMessage.senderUsername !== this.props.user.username) {
      readOnly = true
    }

    return (
      <MessageForm
        title={title}
        readOnly={readOnly}
        apps={[selected]}
        roles={this.props.user.roles}
        channels={selected.channels}
        zapModules={this.props.zapModules}
        defaultApp={loadedMessage.app}
        defaultChannels={loadedMessage.channels}
        defaultTitle={loadedMessage.title}
        defaultBody={loadedMessage.body}
        defaultUrl={loadedMessage.url}
        defaultScreen={loadedMessage.screen}
        defaultSilent={loadedMessage.silent}
        defaultScheduled={loadedMessage.scheduled}
        defaultExpires={loadedMessage.expires}
        onSave={this.save}
        onSubmit={this.submit}
        onClone={this.clone}
      />
    )
  }

  save = (fields) => {
    this.props.postMessage({
      ...fields,
      messageId: this.state.messageId,
      send: false
    })
  }

  submit = (fields) => {
    this.props.postMessage({
      ...fields,
      messageId: this.state.messageId,
      send: true
    })
  }

  clone = (fields) => {
    this.props.postMessage({
      ...fields,
      send: false,
      clone: true
    })
  }
}

const mapStateToProps = state => ({
  apps: state.apps,
  messages: state.messages,
  user: state.user,
  zapModules: state.zapModules,
})

const mapDispatchToProps = dispatch => ({
  selectApp: appId => dispatch(selectApp(appId)),
  fetchAppChannels: appId => dispatch(fetchAppChannels(appId)),
  fetchZapModules: appId => dispatch(fetchZapModules(appId)),
  fetchMessage: (appId, messageId) => dispatch(fetchMessage(appId, messageId)),
  postMessage: fields => dispatch(postMessage(fields)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditMessage)
