import React from 'react'

class NewMessageRequest extends React.Component {
  render() {
    return (
      <p style={{ fontSize: '20px', textAlign: 'center' }}>
        This feature is coming soon.<br />
        Please direct questions to:&nbsp;
        <a href="mailto:mobilizelabs@oarc.ucla.edu">
          mobilizelabs@oarc.ucla.edu
        </a>
      </p>
    )
  }
}

export default NewMessageRequest
