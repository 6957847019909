import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import PrivateRoute from 'pages/PrivateRoute'
import About from 'pages/About'
import Login from 'pages/Login'
import Subscribe from 'pages/Subscribe'
import Verify from 'pages/Verify'
import Preferences from 'pages/Preferences'
import Dashboard from 'pages/Dashboard'
import Tutorials from 'pages/Tutorials'
import NewMessage from 'pages/NewMessage'
import EditMessage from 'pages/EditMessage'
import NewChannelRequest from 'pages/NewChannelRequest'
import ChannelRequestList from 'pages/ChannelRequestList'
import ChannelRequest from 'pages/ChannelRequest'
import NewMessageRequest from 'pages/NewMessageRequest'

import 'normalize.css/normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route exact path="/" component={About} />
            <Route path="/login" component={Login} />
            <Route path="/subscribe/:appId" component={Subscribe} />
            <Route path="/verify/:appId" component={Verify} />
            <Route path="/preferences/:appId" component={Preferences} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/tutorials" component={Tutorials} />
            <PrivateRoute exact path="/messages/new" component={NewMessage} />
            <PrivateRoute path="/messages/:id" component={EditMessage} />
            <PrivateRoute exact path="/channel-requests" component={ChannelRequestList} />
            <PrivateRoute exact path="/channel-requests/new" component={NewChannelRequest} />
            <PrivateRoute path="/channel-requests/:id" component={ChannelRequest} />
            <PrivateRoute exact path="/message-requests/new" component={NewMessageRequest} />
          </Switch>
        </div>
      </BrowserRouter>
    )
  }
}

export default App
