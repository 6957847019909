import React from 'react'
import PropTypes from 'prop-types'
import { H3, HTMLSelect, ButtonGroup, Button, Icon } from '@blueprintjs/core'
import { ResponsiveContainer, BarChart, XAxis, Bar, Tooltip } from 'recharts'
import moment from 'moment'

import Colors from 'styles/colors'

import 'styles/MessagesBarChart.css'

class MessagesBarChart extends React.Component {
  static unitSelectorLabels = {
    days: 'By Hour',
    weeks: 'Week View',
    months: 'Month View',
    years: 'By Month'
  }

  constructor() {
    super()

    this.state = {
      selectedChannel: '',
      unit: 'months',
      timeFrame: 0
    }
  }

  render() {
    const [data, totalCount, prevCount, startMoment] = this.generateData()

    return (
      <div className="MessagesBarChart">
        {this.renderTitle()}
        {this.renderChannelDropdown()}
        {this.renderUnitSelector()}
        {this.renderTimeFrame(startMoment)}
        {this.renderTotals(totalCount, prevCount)}

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.renderLeftArrow(data)}

          <div style={{ width: 'calc(100% - 70px)' }}>
            <ResponsiveContainer width="99%" height={200}>
              <BarChart data={data}>
                <XAxis dataKey="bucket" tickLine={false} />
                <Bar dataKey="count" fill={Colors.darkBlue} />
                <Tooltip />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {this.renderRightArrow()}
        </div>
      </div>
    )
  }

  renderTitle() {
    let title = 'Scheduled Messages'
    if (this.props.type === 'sent') {
      title = 'Message Activity'
    }

    return (
      <H3>
        {title}
      </H3>
    )
  }

  renderChannelDropdown() {
    const options = this.props.channels.map(channel => (
      <option key={channel.id} value={channel.id}>
        {channel.displayName}
      </option>
    ))

    return (
      <HTMLSelect
        iconProps={{ icon: 'chevron-down', color: Colors.blue }}
        defaultValue=""
        onChange={this.onSelectChannel}
      >
        <option key="" value="">
          All Channels
        </option>

        {options}
      </HTMLSelect>
    )
  }

  renderTotals(totalCount, prevCount) {
    const messages = totalCount === 1 ? 'message' : 'messages'
    let prevText = 'Unchanged'
    let prevColor = null

    if (prevCount === 0) {
      prevText = 'No data'
    }
    else if (totalCount > prevCount) {
      prevColor = Colors.green
      prevText = String.fromCharCode(0x2191) + ' '
      prevText += Math.floor((totalCount / prevCount) * 100) - 100
      prevText += '%'
    }
    else if (totalCount < prevCount) {
      prevColor = Colors.red
      prevText = String.fromCharCode(0x2193) + ' '
      prevText += Math.floor((totalCount / prevCount) * 100) - 100
      prevText += '%'
    }

    prevText += ' from previous ' + this.state.unit.replace(/s$/, '')

    return (
      <React.Fragment>
        <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
          {totalCount}
        </span>
        {' '} {messages} {this.props.type}
        <br />
        <span style={{ whiteSpace: 'nowrap', color: prevColor }}>
          {prevText}
        </span>
      </React.Fragment>
    )
  }

  renderLeftArrow(data) {
    return (
      <Icon
        icon="chevron-left"
        iconSize={20}
        className="arrow"
        onClick={this.goBackward}
      />
    )
  }

  renderUnitSelector() {
    const buttons = ['days', 'weeks', 'months', 'years'].map(unit => (
      <Button
        key={unit}
        active={unit === this.state.unit}
        onClick={() => this.setUnit(unit)}
      >
        {MessagesBarChart.unitSelectorLabels[unit]}
      </Button>
    ))

    return (
      <ButtonGroup fill>
        {buttons}
      </ButtonGroup>
    )
  }

  renderTimeFrame(startMoment) {
    let timeFrame

    if (this.state.unit === 'days') {
      timeFrame = startMoment.format('MMMM D, YYYY')
    }
    else if (this.state.unit === 'weeks') {
      timeFrame = startMoment.format('wo [week] of YYYY')
    }
    else if (this.state.unit === 'months') {
      timeFrame = startMoment.format('MMMM YYYY')
    }
    else {
      timeFrame = startMoment.format('YYYY')
    }

    return (
      <div style={{ fontSize: '18px' }}>
        {timeFrame}
      </div>
    )
  }

  renderRightArrow() {
    let className = "arrow"
    if (this.props.type !== 'scheduled' && this.state.timeFrame === 0) {
      className += ' invisible'
    }

    return (
      <Icon
        icon="chevron-right"
        iconSize={20}
        className={className}
        onClick={this.goForward}
      />
    )
  }

  onSelectChannel = (event) => {
    this.setState({
      selectedChannel: event.currentTarget.value
    })
  }

  setUnit = (unit) => {
    this.setState({
      unit,
      timeFrame: 0
    })
  }

  goBackward = () => {
    this.setState(prevState => ({
      timeFrame: prevState.timeFrame + 1
    }))
  }

  goForward = () => {
    this.setState((prevState) => {
      let timeFrame = prevState.timeFrame - 1

      if (this.props.type !== 'scheduled') {
        timeFrame = Math.max(0, timeFrame)
      }

      return {
        timeFrame
      }
    })
  }

  generateData() {
    let messages = this.props.messages
    let buckets = []
    let totalCount = 0
    let prevCount = 0

    // First filter out only messages sent to the selected channel
    // (unless selectedChannel is empty, which means all channels)
    if (this.state.selectedChannel) {
      messages = messages.filter(message => (
        message.channels.includes(this.state.selectedChannel)
      ))
    }

    // Calculate the beginning of the time frame to display messages for
    const unit = this.state.unit
    const startMoment = moment().startOf(unit)
    startMoment.subtract(this.state.timeFrame, unit)

    // Set up buckets depending on the unit
    if (unit === 'days') {
      buckets = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
      buckets.push(12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11)
    }
    else if (unit === 'weeks') {
      buckets = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
    }
    else if (unit === 'months') {
      const daysInMonth = startMoment.daysInMonth()
      buckets = Array.from({ length: daysInMonth }, (x, i) => i + 1)
    }
    else if (unit === 'years') {
      buckets = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
    }

    // Initialize all buckets to 0
    let data = buckets.map(bucket => ({
      bucket,
      count: 0
    }))

    messages.forEach((message) => {
      const messageMoment = moment(message[this.props.type])
      const startOf = messageMoment.clone().startOf(unit)

      if (startOf.isSame(startMoment)) {
        let bucketNumber = messageMoment.hour()

        if (unit === 'weeks') {
          bucketNumber = messageMoment.day()
        }
        else if (unit === 'months') {
          bucketNumber = messageMoment.date() - 1
        }
        else if (unit === 'years') {
          bucketNumber = messageMoment.month()
        }

        ++data[bucketNumber].count
        ++totalCount
      }

      // Also add up messages that fell in the previous time frame
      else if (startOf.add(1, unit).isSame(startMoment)) {
        ++prevCount
      }
    })

    return [data, totalCount, prevCount, startMoment]
  }
}

MessagesBarChart.propTypes = {
  type: PropTypes.oneOf(['scheduled', 'sent']).isRequired,
  channels: PropTypes.arrayOf(PropTypes.object).isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default MessagesBarChart
