import React from 'react'
import { H1, H2 } from '@blueprintjs/core'

import Header from 'components/Header'
import Footer from 'components/Footer'

import hero from 'images/hero.png'
import device from 'images/device.png'
import screens from 'images/screens.png'
import oarc from 'images/oarc.png'
import uclaMade from 'images/uclaMade.png'

import 'styles/About.css'

class About extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header />

        <content className="About">
          <div className="gradient-top">
            <H1>
              UCLA Messaging
            </H1>

            <img src={hero} alt="Mobile app screenshots" className="hero" />

            <H2 className="message-directly">
              Communicate in real time with your organization's audience
            </H2>
          </div>

          <div className="gradient-bottom">
            <div className="strategy">
              <img src={device} alt="" />

              <H2>
                Manage your <br />
                message strategy
              </H2>

              <img
                src={screens}
                alt="Messaging dashboard screenshots"
              />
            </div>

            <div className="contact">
              <img src={oarc} alt="UCLA Office of Information Technology" />

              <a href="mailto:mobilizelabs@oarc.ucla.edu">
                Contact: mobilizelabs@oarc.ucla.edu
              </a>
            </div>

            <Footer light />
          </div>

          <img src={uclaMade} alt="UCLA Made" className="ucla-made" />
        </content>
      </React.Fragment>
    )
  }
}

export default About
