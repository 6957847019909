import React from 'react'
import { connect } from 'react-redux'
import {
  H1,
  RadioGroup,
  Radio,
  FormGroup,
  InputGroup,
  Checkbox
} from '@blueprintjs/core'
import queryString from 'query-string'

import { fetchApps, fetchAppChannelsUnfiltered } from 'store/apps'
import {
  fetchSubscribedChannels,
  updateSubscribedChannels,
  unsubscribe
} from 'store/subscription'
import Status from 'components/Status'
import StyledButton from 'components/StyledButton'

// Simple regexes to catch common errors, not exhaustive
const reEmail = new RegExp(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/)
const reSMS = new RegExp(/^\+1[0-9]{10}$/)

class Preferences extends React.Component {
  constructor(props) {
    super(props)

    const params = queryString.parse(props.location.search)
    let method = ''
    let address = ''

    if (params.email) {
      method = 'email'
      address = params.email
    }
    else if (params.sms) {
      method = 'sms'
      address = params.sms.replace(/^\+1/, '')
    }

    this.state = {
      method,
      address,
      code: params.code || '',
      channels: {},
      unsubscribe: false
    }
  }

  componentDidMount() {
    this.fetchAppAndChannels()
  }

  async fetchAppAndChannels() {
    await this.props.fetchApps()
    await this.props.fetchAppChannelsUnfiltered(this.props.match.params.appId)
  }

  render() {
    if (this.props.apps.length < 1) {
      return null
    }

    const matchingApp = this.props.apps.find(app => (
      app.id === this.props.match.params.appId
    ))

    if (!this.props.match.params.appId || !matchingApp) {
      return (
        <Status>
          App not found.
        </Status>
      )
    }

    else if (this.props.subscription.unsubscribed) {
      return (
        <Status>
          <p>
            You are now unsubscribed.
          </p>
        </Status>
      )
    }

    else {
      return (
        <Status>
          <H1>
            {matchingApp.displayName}
          </H1>

          {this.renderMethodSelector()}
          {this.renderEmailInput()}
          {this.renderSMSInputs()}
          {this.renderCodeInput()}
          {this.renderChannelSelector()}
          {this.renderSubmitButton()}
        </Status>
      )
    }
  }

  renderMethodSelector() {
    if (this.isLoggedIn()) {
      return null
    }

    return (
      <RadioGroup
        label="Communication Method"
        name="method"
        selectedValue={this.state.method}
        onChange={this.changeInput}
        inline
      >
        <Radio label="Email" value="email" />
        <Radio label="Text" value="sms" />
      </RadioGroup>
    )
  }

  renderEmailInput() {
    if (this.isLoggedIn() || this.state.method !== 'email') {
      return null
    }

    return (
      <FormGroup
        labelFor="email"
        label="Email Address"
        inline
        style={{ justifyContent: 'center' }}
      >
        <InputGroup
          type="email"
          id="email"
          name="email"
          value={this.state.address}
          onChange={this.changeInput}
        />
      </FormGroup>
    )
  }

  renderSMSInputs() {
    if (this.isLoggedIn() || this.state.method !== 'sms') {
      return null
    }

    return (
      <div>
        <p>
          Please enter a 10-digit US phone number.
          Non-digit characters are ignored.
        </p>
        <FormGroup
          labelFor="sms"
          label="Phone Number"
          inline
          style={{ justifyContent: 'center' }}
        >
          <InputGroup
            type="tel"
            id="sms"
            name="sms"
            value={this.state.address}
            onChange={this.changeInput}
          />
        </FormGroup>
      </div>
    )
  }

  renderCodeInput() {
    if (this.isLoggedIn() || !this.state.method) {
      return null
    }

    return (
      <FormGroup
        labelFor="code"
        label="Code"
        inline
        style={{ justifyContent: 'center' }}
      >
        <InputGroup
          type="number"
          id="code"
          name="code"
          value={this.state.code}
          onChange={this.changeInput}
        />
      </FormGroup>
    )
  }

  renderChannelSelector() {
    if (!this.isLoggedIn()) {
      return null
    }

    const matchingApp = this.props.apps.find(app => (
      app.id === this.props.match.params.appId
    ))
    if (!matchingApp || !matchingApp.channels) {
      return null
    }

    const channels = matchingApp.channels.filter(channel => (
      channel.id !== 'all' && channel.id !== 'staging-all'
    ))
    let channelSelector = null

    if (channels.length > 0) {
      const checkboxes = channels.map(channel => (
        <Checkbox
          key={channel.id}
          name={channel.id}
          label={channel.displayName}
          disabled={this.state.unsubscribe}
          defaultChecked={this.props.subscription.channels.includes(channel.id)}
          onChange={this.checkChannel}
        />
      ))

      channelSelector = (
        <div>
          <p>
            Check the channels you would like to receive messages about.
          </p>
          {checkboxes}
          <hr />
          <p>
            OR
          </p>
        </div>
      )
    }

    return (
      <div>
        {channelSelector}
        <Checkbox
          label="Unsubscribe from all notifications"
          onChange={this.checkUnsubscribe}
        />
      </div>
    )
  }

  renderSubmitButton() {
    if (!this.state.method) {
      return null
    }

    return (
      <StyledButton
        colorClass="yellow"
        onClick={this.submit}
        style={{ width: '180px' }}
        disabled={!this.isFormValid()}
      >
        Submit
      </StyledButton>
    )
  }

  changeInput = (event) => {
    let name = event.currentTarget.name
    if (name === 'email' || name === 'sms') {
      name = 'address'
    }

    let newState = {}
    newState[name] = event.currentTarget.value

    this.setState(newState)
  }

  checkChannel = (event) => {
    const channel = event.currentTarget.name
    const checked = event.currentTarget.checked

    this.setState((prevState) => {
      let newChannels = {...prevState.channels}
      newChannels[channel] = checked

      return {
        channels: newChannels
      }
    })
  }

  checkUnsubscribe = (event) => {
    this.setState({
      unsubscribe: event.currentTarget.checked
    })
  }

  submit = () => {
    const appId = this.props.match.params.appId
    const prettyState = this.getPrettyState()

    if (this.state.unsubscribe) {
      this.props.unsubscribe(appId, prettyState)
    }

    else if (this.isLoggedIn()) {
      let channels = {}
      const matchingApp = this.props.apps.find(app => (
        app.id === this.props.match.params.appId
      ))

      // First populate the channels hash from all available channels
      matchingApp.channels.forEach((channel) => {
        if (channel.id !== 'all' && channel.id !== 'staging-all') {
          channels[channel.id] = false
        }
      })

      // Next, set the channels which were already subscribed
      this.props.subscription.channels.forEach((channelId) => {
        if (channelId !== 'all' && channelId !== 'staging-all') {
          channels[channelId] = true
        }
      })

      // Finally, apply any changes from state
      Object.keys(prettyState.subscriptions).forEach((channelId) => {
        channels[channelId] = prettyState.subscriptions[channelId]
      })

      prettyState.subscriptions = channels
      this.props.updateSubscribedChannels(appId, prettyState)
    }

    else {
      this.props.fetchSubscribedChannels(appId, prettyState)
    }
  }

  isLoggedIn() {
    return this.props.subscription.channels ? true : false
  }

  isFormValid() {
    const prettyState = this.getPrettyState()

    if (!prettyState.code) {
      return false
    }

    else if (prettyState.method === 'email') {
      // Extremely basic email test, just to filter out basic mistakes.
      // No point in full validation here since a confirmation email must
      // be answered anyway.
      return reEmail.test(prettyState.address)
    }

    else if (prettyState.method === 'sms') {
      // Only support US numbers for now
      return reSMS.test(prettyState.address)
    }

    return false
  }

  getPrettyState() {
    return {
      method: this.state.method,
      address: this.state.address,
      code: this.state.code.trim(),
      subscriptions: this.state.channels,
      unsubscribe: this.state.unsubscribe
    }
  }
}

const mapStateToProps = state => ({
  apps: state.apps,
  subscription: state.subscription
})

const mapDispatchToProps = dispatch => ({
  fetchApps: () => dispatch(fetchApps()),
  fetchAppChannelsUnfiltered: appId => dispatch(
    fetchAppChannelsUnfiltered(appId)
  ),
  fetchSubscribedChannels: (appId, formData) => dispatch(
    fetchSubscribedChannels(appId, formData)
  ),
  updateSubscribedChannels: (appId, formData) => dispatch(
    updateSubscribedChannels(appId, formData)
  ),
  unsubscribe: (appId, formData) => dispatch(unsubscribe(appId, formData))
})

export default connect(mapStateToProps, mapDispatchToProps)(Preferences)
